import React, {Component} from 'react';
import moment from 'moment';
import Ry from 'ryvendor/Ry/Core/Ry';
import trans from '../../translations';
import Modelizer from 'ryvendor/Ry/Core/Modelizer';
import Localtime from './Localtime';
import {Popup, PopupBody, PopupHeader} from 'ryvendor/bs/bootstrap';
import $ from 'jquery';
import numeral from "numeral";

class ReceptacleLine extends Component
{
    constructor(props) {
        super(props)
        this.state = {
            status: 0
        }
    }

    scan(consignment_event) {
        let resdit = null
        if(this.props.import.has_delivery == 0) {
            resdit = <label className="fancy-radio m-auto custom-color-green">
                <input name={`receptacles[${this.props.data.id}][delivered]`} type="radio" value={consignment_event} checked={this.models('props.data.delivered', 0)==consignment_event} onChange={()=>this.props.handleReceptacleStatusChange(consignment_event)}/>
                <span><i className="mr-0"></i></span>
            </label>
        }
        return resdit
    }

    render()
    {
        return <tr>
                    <td className="text-left">
                        {this.props.data.receptacle_id} {this.props.readOnly?<React.Fragment><a href={`#barcode?code=${this.props.data.receptacle_id}`} onClick={()=>$(`#receptacle${this.props.data.receptacle_id}`).modal('show')}><i className="fa fa-barcode"></i></a>
                        <Popup id={`receptacle${this.props.data.receptacle_id}`} className="modal-xl">
                            <PopupBody>
                                <div className="text-center py-5">
                                    <img src={`/barcode?code=${this.props.data.receptacle_id}`}/>
                                </div>
                            </PopupBody>
                        </Popup>
                        </React.Fragment>:null}
                    </td>
                    <td>{this.props.data.dispatch_number}</td>
                    <td>{this.props.data.recipient_number}</td>
                    <td>{this.props.data.attributed}</td>
                    <td>{numeral(parseFloat(this.props.data.weight)).format('0,0.0')}</td>
                    {(this.props.import.has_delivery == 0) ? <td className="text-center">
                        {this.scan(1)}
                    </td> : null }
                </tr>
    }
}

Modelizer(ReceptacleLine);

class Reception extends Component
{
    constructor(props) {
        super(props)
        this.state = {
            receptacles : this.props.data.receptacles,
            dialogs : [{}],
            dirty: false,
            data: this.props.data,
            date_delivery: moment(),
            status: 0
        }
        this.handleReceptacleStatusChange = this.handleReceptacleStatusChange.bind(this)
        this.handleAllReceptacleStatusChange = this.handleAllReceptacleStatusChange.bind(this)
        this.selectUld = this.selectUld.bind(this)
        this.handleChangeDeliverydate = this.handleChangeDeliverydate.bind(this)
    }

    handleChangeDeliverydate(e) {
        const value = e.target.value
        this.setState(state=>{
            state.date_delivery = value
            return state
        })
    }

    handleReceptacleStatusChange(receptacle, status) {
        this.setState(state=>{
            const _receptacle = state.receptacles.find(it=>it.id == receptacle.id)
            state.dirty = true
            _receptacle.delivered = status
            return state
        })
    }

    handleAllReceptacleStatusChange() {
        let status = (this.state.status == 0) ? 1 : 0
        this.setState(state=>{
            state.dirty = true
            state.receptacles.map(it=>{
                it.delivered = status
            })
            state.status = status
            return state
        })
    }

    selectUld() {
        $(`#select-uld-${this.props.data.id}`).modal('show')
    }

    componentDidMount()
    {
        this.unsubscribe = this.props.store.subscribe(()=>{
            const storeState = this.props.store.getState()
            if(storeState.type=='scan' && storeState.reception && storeState.reception.length>0 && storeState.reception[0].cardit_id==this.props.data.id) {
                this.setState(state => {
                    state.dialogs.push({})
                    return state
                })
            }
            if(storeState.type==='mld_sent') {
                setTimeout(()=>{
                    document.location.reload()
                }, 1000)
            }
        })
        const opts = {
            language : 'fr',
            autoclose : true
        }
        const dp = $(this.refs.datepicker).datepicker(opts)
        dp.on("changeDate", ()=>{
            const date = moment(dp.datepicker('getDate')).format('YYYY-MM-DD')

            this.setState(state=>{
                state.date_delivery = date
                return state
            })
        });
    }

    componentWillUnmount() {
        this.unsubscribe()
    }

    render() 
    {
        return <div className="row">
            <div className="col-md-12 d-md-block d-xl-none">
                <div className="row text-left text-body">
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-md-6">
                                <small className="text-muted">{trans('Nombre de récipient')} :</small><br/>
                                {this.state.receptacles.length}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        
                    </div>
                </div>
            </div>
            <div className="col-xl-3 d-md-none d-xl-block">
                <div className="blockTemps">
                    <ul className="info"> 
                        <li>
                            {trans('Nombre de récipient')} :
                            <span>{this.state.receptacles.length}</span>
                        </li>
                        {/* <li>
                            {trans('Origine')}<br/>
                            <strong className="text-wrap">{this.props.data.provenance.country.nom} - {this.props.data.provenance.iata} - {this.props.data.provenance.name}</strong>
                        </li> */}
                        <li>
                            {trans('Arrivée')}<br/>
                            <strong className="text-wrap">
                                {this.props.data.arrival_datetime_lt!='0000-00-00 00:00:00'?moment(this.props.data.arrival_datetime_lt).format('DD/MM/YYYY HH:mm'):'00/00/00 00:00'}
                            </strong>
                        </li>
                        <li>
                            <strong className="text-wrap">
                                {trans("MLD RCF envoyé le :date", {
                                    date: moment.utc(this.models('props.data.handovered_at')).format('DD/MM/YYYY HH:mm')
                                })}
                            </strong>
                        </li>
                        {(this.props.data.has_delivery == 1) ? <li>
                            <strong className="text-wrap">
                                {trans("MLD DLV envoyé le :date", {
                                    date: moment.utc(this.models('props.data.date_delivery')).format('DD/MM/YYYY HH:mm')
                                })}
                            </strong>
                        </li> : null}
                    </ul>
                </div>
            </div>
            <div className="col-xl-9">
                <div className="table-responsive">
                    <form name={`frm_cardit${this.props.data.id}`} action={`/delivery_import`} method="post">
                        {this.state.dialogs.map((v,k)=><Ry key={`ajaxform-${k}`} title="ajaxform"/>)}
                        <input type="hidden" name="ry"/>
                        <input type="hidden" name="id" value={this.props.data.id}/>
                        <table className="table tableRecap">
                            <thead>
                            {(this.props.data.has_delivery == 0) ? <React.Fragment>
                                <tr>
                                    <th rowSpan="2" colSpan="5"className="colorVert noBor pl-0 text-left text-wrap"></th>
                                    <th className="thTop">{trans('POD')}</th>
                                </tr>
                                <tr className="thLeft">
                                    <th>DLV</th>
                                </tr> 
                            </React.Fragment>: null }
                                <tr>
                                    <th width="300">{trans('Receptacle ID')}</th>
                                    <th width="250">{trans('Disptach number')}</th>
                                    <th width="150">{trans('N° de récipient')}</th>
                                    <th>{trans('Flux')}</th>
                                    <th>{trans('Poids (Kg)')}</th>
                                    {(this.props.data.has_delivery == 0) ? <th width="10%">
                                        <label className="fancy-radio custom-color-green m-auto">
                                            <input type="radio" name="checkall[status]" onChange={()=>this.handleAllReceptacleStatusChange()}/>
                                            <span><i className="m-0"></i></span>
                                        </label>
                                    </th> : null }
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.receptacles.map((receptacle, index)=><ReceptacleLine key={`content-reception-${receptacle.id}`} import={this.props.data} data={receptacle} messageFunction={this.models('props.data.nsetup.message_function')} readOnly={this.props.readOnly} handleReceptacleStatusChange={code=>this.handleReceptacleStatusChange(receptacle, code)}/>)}
                                <tr>
                                {(this.props.data.has_delivery == 0) ? <td colSpan="5" className="border-right-0 noBg"></td> : <td colSpan="4" className="border-right-0 noBg"></td>}
                                    <td className="border-left-0 border-right-0 p-0">
                                        {this.state.dirty?<button className="btn btn-orange rounded-0" type="button" onClick={this.selectUld}>{trans('Valider')}</button>:null}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <input type="hidden" name="date_delivery" value={this.models('state.data.date_delivery')}/>
                        <Popup id={`select-uld-${this.props.data.id}`}>
                            <PopupHeader>
                                {trans("POD Confirmation")}
                            </PopupHeader>
                            <PopupBody>
                                <div className='row'>
                                    <div className="col-md-5">
                                        <input type="hidden" name="pod_date" value={moment(this.models('state.date_delivery')).format('YYYY/MM/DD')} />
                                        <div className="align-items-baseline d-flex form-group ml-2">
                                            <label className="control-label text-capitalize mr-4">
                                                {trans("Date")}
                                            </label>
                                            <div ref="datepicker" className="input-group date">
                                                <input type="text" className={`form-control bs-default`} required data-parsley-errors-container={`#departure_date-${this.props.data.id}-error`} value={moment(this.state.date_delivery).format("DD/MM/YYYY")} onChange={this.handleChangeDeliverydate}/>
                                                <div className="input-group-append"> 
                                                    <button className="btn-primary btn text-light pl-3 pr-3" type="button"><i className="fa fa-calendar-alt"></i></button>
                                                </div>
                                            </div>
                                            <span id={`departure_date-${this.props.data.id}-error`}></span>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="align-items-baseline d-flex form-group ml-2">
                                            <label className="control-label mr-4">
                                                {trans('Heure')}
                                            </label>
                                            <input type="time" name="delivery_time" className={`form-control bs-default`} required defaultValue={moment(this.models('state.date_delivery')).format('HH:mm')}/>
                                        </div>
                                    </div>
                                </div>
                                <button className="btn btn-beige text-light py-2 mt-3">{trans('Valider')}</button>
                            </PopupBody>
                        </Popup>
                        <Localtime/>
                    </form>
                </div>
            </div>
        </div>
    }
}

export default Modelizer(Reception);